import axios from 'axios'
import router from "@/router";

const state = {
    project: {},
    project_quotas: [],
    projects: {},
    project_errors: {},
    project_loading: false,
}

const getters = {
    project: state => state.project,
    project_quotas: state => state.project_quotas,
    projects: state => state.projects,
    project_errors: state => state.project_errors,
    project_loading: state => state.project_loading,
}

const mutations = {
    setProject(state, project) {
        state.project = project
    },
    setProjectQuotas(state, project_quotas) {
        state.project_quotas = project_quotas
    },
    setProjects(state, projects) {
        state.projects = projects
    },
    setProjectErrors(state, project_errors) {
        state.project_errors = project_errors
    },
    setProjectLoading(state, project_loading) {
        state.project_loading = project_loading
    },
}

const actions = {
    async storeProject({commit}, data) {
        commit('setProjectLoading', true)
        commit('setProjectErrors', {})
        try {
            let response = await axios.post(`/api/projects/store`, data)
            commit('setProjectLoading', false)
            commit('setProjectErrors', {})
            if (response.data.ok)
                router.push({name: 'vpc.quotas', params: {project_id: response.data?.data?.id}})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setProjectLoading', false)
            commit('setProjectErrors', error.response.data.errors)
            return false
        }
    },
    async getProjects({commit}, data) {
        try {
            let response = await axios.get(`/api/projects`, data)
            commit('setProjects', response.data?.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
                return false
        }
    },
    async getProjectQuotas({commit}, data) {
        try {
            let response = await axios.get(`/api/projects/${data.id}/quotas`,)
            commit('setProjectQuotas', response.data?.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async checkProjectDeletable({commit}, id) {
        commit('setProjectLoading', true)
        try {
            let response = await axios.get(`/api/projects/${id}/check-deletable`)
            commit('setProjectLoading', false)
            return response.data.ok
        } catch (error) {
            commit('setProjectLoading', false)
            commit('setProjectErrors', error.response?.data?.errors)
            return false
        }
    },
    async destroyProject({commit}, id) {
        commit('setProjectLoading', true)
        try {
            let response = await axios.delete(`/api/projects/${id}/destroy`)
            commit('setProjectLoading', false)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async selectProject({commit}, id) {
        commit('setProjectLoading', true)
        try {
            let response = await axios.post(`/api/projects/${id}/select`)
            commit('setProjectLoading', false)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async updateProject({commit}, data) {
        commit('setProjectLoading', true)
        commit('setProjectErrors', {})
        try {
            let response = await axios.put(`/api/projects/${data.id}/update`, data)
            commit('setProjectLoading', false)
            commit('setProjectErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setProjectLoading', false)
            commit('setProjectErrors', error.response.data.errors)
            return false
        }
    },
    async updateProjectQuotas({commit}, data) {
        commit('setProjectLoading', true)
        commit('setProjectErrors', {})
        try {
            let response = await axios.put(`/api/projects/${data.id}/quotas/update`, data)
            commit('setProjectLoading', false)
            commit('setProjectErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setProjectLoading', false)
            commit('setProjectErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}