import axios from 'axios'

const state = {
    cloud_host: {},
    networks: {},
    elastic_ips: {},
    routers: {},
    firewalls: {},
    firewall: {},
    cloud_hosts: {},
    disks: {},
    vpcs: {},
    cloud_host_errors: {},
    cloud_host_loading: false,
}

const getters = {
    cloud_host: state => state.cloud_host,
    networks: state => state.networks,
    elastic_ips: state => state.elastic_ips,
    routers: state => state.routers,
    firewalls: state => state.firewalls,
    firewall: state => state.firewall,
    cloud_hosts: state => state.cloud_hosts,
    disks: state => state.disks,
    vpcs: state => state.vpcs,
    cloud_host_errors: state => state.cloud_host_errors,
    cloud_host_loading: state => state.cloud_host_loading,
}

const mutations = {
    setCloudHost(state, cloud_host) {
        state.cloud_host = cloud_host
    },
    setCloudHosts(state, cloud_hosts) {
        state.cloud_hosts = cloud_hosts
    },
    setNetworks(state, networks) {
        state.networks = networks
    },
    setElasticIps(state, elastic_ips) {
        state.elastic_ips = elastic_ips
    },
    setRouters(state, routers) {
        state.routers = routers
    },
    setFirewalls(state, firewalls) {
        state.firewalls = firewalls
    },
    setFirewall(state, firewall) {
        state.firewall = firewall
    },
    setVpcs(state, vpcs) {
        state.vpcs = vpcs
    },
    setDisks(state, disks) {
        state.disks = disks
    },
    setCloudHostErrors(state, cloud_host_errors) {
        state.cloud_host_errors = cloud_host_errors
    },
    setCloudHostLoading(state, cloud_host_loading) {
        state.cloud_host_loading = cloud_host_loading
    },
}

const actions = {
    async storeCloudHost({commit}, data) {
        commit('setCloudHostLoading', true)
        commit('setCloudHostErrors', {})
        try {
            let response = await axios.post(`/api/cloud_hosts/store`, data)
            commit('setCloudHostLoading', false)
            commit('setCloudHostErrors', {})
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setCloudHostLoading', false)
            commit('setCloudHostErrors', error.response.data.errors)
            return false
        }
    },
    async getCloudHosts({commit}, project_id) {
        try {
            let response = await axios.get(`/api/cloud-hosts/${project_id}`)
            commit('setCloudHosts', response.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async getDisks({commit}, project_id) {
        try {
            let response = await axios.get(`/api/disks/${project_id}`)
            commit('setDisks', response.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async getNetworks({commit}, params) {
        try {
            let response = await axios.get(`/api/networks/${params.project_id}`, {params})
            commit('setNetworks', response.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async getVpcs({commit}, params) {
        try {
            let response = await axios.get(`/api/vpcs/${params.project_id}`, {params})
            commit('setVpcs', response.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async getFirewalls({commit}, params) {
        try {
            let response = await axios.get(`/api/firewalls/${params.project_id}`, {params})
            commit('setFirewalls', response.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async getAllFirewalls({commit}, params) {
        try {
            let response = await axios.get(`/api/firewalls/${params.project_id}/all`, {params})
            commit('setFirewalls', response.data?.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async getFirewall({commit}, params) {
        try {
            let response = await axios.get(`/api/firewalls/${params.project_id}/${params.id}/show`,)
            commit('setFirewall', response.data.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async getRouters({commit}, params) {
        try {
            let response = await axios.get(`/api/routers/${params.project_id}`, {params})
            commit('setRouters', response.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async getElasticIps({commit}, params) {
        try {
            let response = await axios.get(`/api/elastic-ips/${params.project_id}`, {params})
            commit('setElasticIps', response.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async getCloudHost({commit}, id) {
        try {
            let response = await axios.get(`/api/cloud_hosts/${id}/show`)
            commit('setCloudHost', response.data?.data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            return false
        }
    },
    async updateCloudHost({commit}, data) {
        commit('setCloudHostLoading', true)
        commit('setCloudHostErrors', {})
        try {
            let response = await axios.put(`/api/cloud_hosts/${data.id}/update`, data)
            commit('setCloudHostLoading', false)
            commit('setCloudHostErrors', {})
            return response.data
        } catch (error) {
            console.log(error);
            commit('setCloudHostLoading', false)
            commit('setCloudHostErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}